<template>
  <ys-dialog
      :visible.sync="isVisible"
      @update:visible="visible=>$emit('update:visible', visible)"
      title="添加插件"
  >
    <div class="body padding padding-large">
      <div class="layout-horizontal layout-middle">
        <span class="label layout-inflexible">
        <span class="mark-required"></span>
        <span>名称：</span>
        </span>
        <ys-input
            class="layout-flexible"
            placeholder="请输入插件名称"
            :maxlength="80"
            v-model="nameInputted"
        ></ys-input>
      </div>
      <div class="margin-top layout-horizontal layout-middle">
        <span class="label layout-inflexible">
        <span class="mark-required"></span>
        <span class="layout-inflexible">标识符（tag)：</span>
        </span>
        <ys-input
            class="layout-flexible"
            placeholder="请输入插件标识，标识必须唯一"
            :maxlength="80"
            v-model="tagInputted"
            @input="handleTagInput"
        ></ys-input>
      </div>
      <div class="duplicated-tag margin-top" v-if="duplicatedPlugin">该标识符已被：{{ duplicatedPlugin.name }} 占用，请重新设置</div>
    </div>
    <div slot="footer">
      <ys-button lighting type="secondary" @click="handleCancelClick">取消</ys-button>
      <ys-button lighting class="margin-left" @click="handleAddPluginClick"><i class="fas fa-plus-circle"></i> 添加插件</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import httpapi from "@/assets/javascript/httpapi";
import TextUtils from "@/assets/javascript/text-utils";

function doCheckTagExists(tag) {
  this.$reqGet({
    path: '/plugin/module/get/by/tag',
    data: {
      tag,
    }
  }).then(res => {
    if (tag === this.tagInputted) {
      this.duplicatedPlugin = res.data;
    }
  }).catch(() => {
    this.$message.error('加载失败');
  });
}

function doAddPlugin() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.buttonTexts = loadingCode;
  this.$reqPost({
    path: '/plugin/module/add/empty',
    data: {
      name: this.nameInputted,
      tag: this.tagInputted,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.isVisible = true;
    this.nameInputted = null;
    this.tagInputted = null;
    this.$emit('update:visible', false);
    this.$emit('confirm', res.data.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PluginAddDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsDialog},
  props: {
    visible: Boolean,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      isVisible: false,

      duplicatedPlugin: null,

      nameInputted: null,
      tagInputted: null,

      timeoutIdOfTagChecking: null,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  methods: {
    handleTagInput() {
      if (this.timeoutIdOfTagChecking) {
        clearTimeout(this.timeoutIdOfTagChecking);
      }

      this.timeoutIdOfTagChecking = setTimeout(() => {
        if (TextUtils.isBlank(this.tagInputted)) {
          this.duplicatedPlugin = null;
        } else {
          doCheckTagExists.bind(this)(this.tagInputted);
        }
      }, 500);
    },
    handleAddPluginClick() {
      if(TextUtils.isBlank(this.nameInputted)) {
        this.$message.warning('请输入插件名称');
        return;
      }
      if(TextUtils.isBlank(this.tagInputted)) {
        this.$message.warning('请输入标识符');
        return;
      }
      if(this.duplicatedPlugin) {
        this.$message.warning('标识符被占用，请重新输入');
        return;
      }
      doAddPlugin.bind(this)();
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    }
  },
  destroyed() {
    if (this.timeoutIdOfTagChecking) {
      clearTimeout(this.timeoutIdOfTagChecking);
    }
  }
}
</script>

<style scoped>

.body {
  max-width: 500px;
}

.mark-required:after {
  content: "*";
  color: #ff5500;
}

.logo {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f1f1f1;
  font-size: 48px;
  line-height: 48px;
}

.label {
  width: 100px;
  text-align: right;
}

.duplicated-tag {
  padding: 4px 8px;
  border-radius: 100px;
  background-color: #f0c78a;
  color: #ff5500;
}

</style>