<template>
  <div class="plugin-list layout-vertical" style="height: 100vh;">
    <div class="layout-horizontal layout-middle padding-horizontal-large padding-vertical layout-inflexible">
      <back-button @click="$router.back()"></back-button>
      <div class="font-weight-bold font-size-large">插件管理</div>
    </div>
    <div class="layout-flexible" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%;" @loadmore="loadMore">
        <div class="padding">
          <div class="btn-add-plugin" @click="handleAddPluginClick">
            <span class="font-size-medium"><i class="fas fa-plus-circle"></i> 添加新插件</span>
          </div>
          <div class="list-item layout-horizontal layout-middle" v-for="plugin in pluginList" :key="plugin.id"
            @click="handlePluginClick(plugin)">
            <div class="layout-inflexible font-size-medium">
              <i class="fas fa-plug"></i>
            </div>
            <div class="margin-left-large layout-flexible">
              <div>
                <span class="font-weight-bold">{{ plugin.tag }}</span>
                <span class="font-color-secondary margin-left">{{ plugin.name }}</span>
              </div>
              <div class="margin-top-small font-color-secondary">
                <span class="mark mark-online" v-if="plugin.online">运行中 <i class="fas fa-play font-size-extra-small"></i></span>
                <span class="mark mark-offline" v-else>未启动 <i class="fas fa-stop font-size-extra-small"></i></span>
                <span v-if="plugin.online"
                      class="margin-left-small">线上版本：{{ plugin.online.majorVersion }}.{{
                    plugin.online.minorVersion
                  }}</span>
                <span v-else-if="plugin.last" class="margin-left-small">最新版本：{{ plugin.last.majorVersion }}.{{ plugin.last.minorVersion }}</span>
                <span v-else class="margin-left-small font-color-danger">未上传</span>
                <span v-if="plugin.online && plugin.last && plugin.online.id !== plugin.last.id" class="font-color-danger margin-left-large">
                  最新版本未启用：{{plugin.last.majorVersion}}.{{plugin.last.minorVersion}}
                </span>
              </div>
            </div>
            <div class="operations layout-inflexible layout-horizontal">
              <el-tooltip content="启动插件" :open-delay="500">
                <ys-button type="secondary" size="small" round lighting :disabled="plugin.online || !plugin.last"
                  @click.stop="handleStartPluginClick(plugin)">
                  <i class="fas fa-play font-size-small"></i>
                </ys-button>
              </el-tooltip>
              <el-tooltip content="停止插件" :open-delay="500" class="margin-left">
                <ys-button type="secondary" size="small" round lighting :disabled="!plugin.online"
                  @click.stop="handleStopPluginClick(plugin)">
                  <i class="fas fa-stop font-size-small"></i>
                </ys-button>
              </el-tooltip>
              <el-tooltip content="上传插件" :open-delay="500" class="margin-left">
                <ys-button type="secondary" size="small" round lighting
                  @click.stop="handleUploadClick(plugin)">
                  <i class="fas fa-cloud-upload-alt font-size-small"></i>
                </ys-button>
              </el-tooltip>
              <el-tooltip content="删除插件" :open-delay="500" class="margin-left">
                <ys-button type="secondary" size="small" round lighting
                  @click.stop="handlePluginDeleteClick(plugin)">
                  <i class="fas fa-trash-alt font-color-danger"></i>
                </ys-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <plugin-add-dialog :visible.sync="pluginAddDialogVisible" @confirm="handlePluginUpdate"></plugin-add-dialog>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        message="您确定要删除该插件吗？"
        @confirm="handlePluginDeleteConfirm"
    ></delete-dialog>
    <delete-dialog
        :visible.sync="stopRemindDialogVisible"
        message="您确定要停止该插件吗？"
        @confirm="handleStopPluginConfirm"
        confirm-button-text="停止"
    ></delete-dialog>
    <plugin-upload-dialog
        :visible.sync="pluginUploadDialogVisible"
        :plugin-id="pluginPicked ? pluginPicked.id : null"
        @confirm="handlePluginUpdate"
    ></plugin-upload-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import PluginAddDialog from "@/pages/plugin/dialog/PluginAddDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import PluginUploadDialog from "@/pages/plugin/dialog/PluginUploadDialog";

function doLoadPunginList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/plugin/module/list/get',
    data: {
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.pluginList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPluginById(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/plugin/module/get',
    data: {
      id,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.pluginList, [res.data]);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStartPlugin(plugin) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$showBusy();
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/set/online',
    data: {
      id: plugin.last.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    plugin.online = plugin.last;
    this.busyLoadingCode = 0;
    doLoadPluginById.bind(this)(plugin.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopPlugin(plugin) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$showBusy();
    return;
  }
  let loadingCode = this.loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/set/offline',
    data: {
      id: plugin.online.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    plugin.online = null;
    this.busyLoadingCode = 0;
    doLoadPluginById.bind(this)(plugin.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemovePlugin(id) {
  if(this.loadingCode === this.busyLoadingCode){
    this.$message.error('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/remove',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    for(let n = this.pluginList.length - 1; n >= 0; n --) {
      if(this.pluginList[n].id === id) {
        this.pluginList.splice(n, 1);
        break;
      }
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })

}

export default {
  name: "PluginList",
  mixins: [httpapi],
  components: {PluginUploadDialog, DeleteDialog, PluginAddDialog, YsButton, YsInfiniteScrollView, BackButton},
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      pluginList: [],
      pageNum: 1,
      totalPages: 0,

      pluginAddDialogVisible: false,
      deleteRemindDialogVisible: false,
      pluginUploadDialogVisible: false,
      stopRemindDialogVisible: false,

      pluginPicked: null,

    }
  },
  activated() {
    this.loadingCode++;
    this.pluginList = [];
    this.pageNum = 1;
    this.totalPages = 0;
    this.loadPluginList();
  },
  methods: {
    loadPluginList(pageNum = 1) {
      doLoadPunginList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPluginList(this.pageNum + 1);
      }
    },
    handleAddPluginClick() {
      this.pluginAddDialogVisible = true;
    },
    handlePluginUpdate(id) {
      doLoadPluginById.bind(this)(id);
    },
    handlePluginDeleteClick(plugin) {
      this.pluginPicked = plugin;
      this.deleteRemindDialogVisible = true;
    },
    handlePluginDeleteConfirm() {
      let plugin = this.pluginPicked;
      if(plugin) {
        doRemovePlugin.bind(this)(plugin.id);
      }
    },
    handleUploadClick(plugin) {
      this.pluginPicked = plugin;
      this.pluginUploadDialogVisible = true;
    },
    handleStartPluginClick(plugin) {
      if(!plugin.last || plugin.online) return;
      doStartPlugin.bind(this)(plugin);
    },
    handleStopPluginClick(plugin) {
      if(!plugin.online) return;
      this.pluginPicked = plugin;
      this.stopRemindDialogVisible = true;
    },
    handleStopPluginConfirm() {
      let plugin = this.pluginPicked;
      if(plugin && plugin.online) {
        doStopPlugin.bind(this)(plugin);
      }
    },
    handlePluginClick(plugin) {
      this.$router.push({
        name: 'plugin',
        query: {id: plugin.id}
      })
    }
  }
}
</script>

<style scoped>

.btn-add-plugin {
  width: 200px;
  height: 100px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dadada;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-add-plugin:hover {
  box-shadow: 0 0 4px #bec0c0;
}

.mark {
  padding: 2px 4px;
  font-size: 10px;
  line-height: 14px;
  border-radius: 2px;
}

.mark.mark-online {
  background-color: #f091a6;
  color: white;
  box-shadow: 0 0 2px #f091a6;
}

.mark.mark-offline {
  background-color: #f1f1f1;
  color: #7d7c7c;
}

.list-item .operations {
  visibility: hidden;
}

.list-item:hover .operations {
  visibility: visible;
}

</style>